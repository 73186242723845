<script setup>
    import { Head, Link } from "@inertiajs/vue3"
    import Logo from "@/Components/Logo.vue"
    import SecondaryButtonLink from "@/Components/SecondaryButtonLink.vue"
    import GitlabIcon from "@/Components/GitlabIcon.vue"
    import PrimaryButtonLink from "@/Components/PrimaryButtonLink.vue"
    import { usePolicies } from "@/Composables/Permissions.js"

    const { can } = usePolicies()

    defineProps({
        canLogin: Boolean,
        canTrackTime: Boolean,
        canSeeInfrastructure: Boolean,
        canCreateInfrastructure: Boolean,
    })
</script>

<template>
    <Head title="Welcome" />

    <div
        class="bg-dots-darker dark:bg-dots-lighter relative min-h-screen bg-gray-100 bg-center selection:bg-red-500 selection:text-white sm:flex sm:items-center sm:justify-center dark:bg-gray-900"
    >
        <div
            v-if="canLogin"
            class="p-6 text-right sm:fixed sm:right-0 sm:top-0"
        >
            <Link
                v-if="$page.props.auth.user"
                :href="route('dashboard')"
                class="font-semibold text-gray-600 hover:text-gray-900 focus:rounded-sm focus:outline focus:outline-2 focus:outline-brand-primary dark:text-gray-400 dark:hover:text-white"
                >Dashboard</Link
            >

            <template v-else>
                <div class="flex max-w-2xl flex-wrap justify-end gap-4">
                    <SecondaryButtonLink
                        :asVueLink="false"
                        :href="route('oauth.login')"
                    >
                        <GitlabIcon class="mr-2 inline-block h-4 w-4" /> Log in with GitLab
                    </SecondaryButtonLink>
                    <PrimaryButtonLink :href="route('login')">Log in</PrimaryButtonLink>
                </div>
            </template>
        </div>

        <div class="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
            <h1
                v-if="can('seeEmployeeResources')"
                class="text-center text-4xl font-bold text-gray-800 sm:text-6xl md:text-8xl dark:text-white"
            >
                Opstimus Prime
            </h1>
            <div class="flex justify-center pt-8">
                <Logo />
            </div>
            <h1
                v-if="!can('seeEmployeeResources')"
                class="text-center text-4xl font-bold text-gray-800 dark:text-white"
            >
                LuminFire Dashboard
            </h1>
        </div>
    </div>
</template>
